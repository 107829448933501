@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{

  top: 0;
  left: 0;
  margin: 0;
 font-family: 'Montserrat',sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}
.navbar-toggler {
  padding: 10px 6px;

}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler span {
  display: block;
  height: 2px;
  border: 2px;
  background: rgb(33 82 148);
  ;
}

.navbar-toggler span+span {
  margin-top: 6px;
  /* width: 25px; */

}

nav .nav-item {
  padding: 10px 23px;
}

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}


.toggler-icon {
  width: 30px;
  height: 3px;

  display: block;
  transition: all 0.5s;
}


.middle-bar {
  margin: 5px auto;
}


.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
          transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
          transform-origin: 10% 90%;
}



.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
html,
body {

    top: 0;
    left: 0;
    margin: 0;
  font-family: 'Montserrat';
    box-sizing: border-box;
    overflow-x: hidden;
}


/* header part css  */

.headerpart {
    background-color: #f5f5f5;
    height: auto;
    align-items: center;
    padding: 3rem 0rem;
    padding-bottom: 7rem;

}

.firstbox {
    background-color: rgb(0, 0, 71);
    height: 23rem;
    width: 100%;
    max-width: 61%;
    margin-left: 20%;
    margin-top: 2rem;
    position: absolute;
}

.firstbox img {
    margin-top: 2.5rem;
    left: 19.5rem;
    position: relative;
    z-index: 1;
}

.secoundbox {
    background-color: #EA4D06;
    color: #fff;
    height: auto;
    max-width: 60%;
    width: 100%;
    padding-top: 6.5rem;
    position: relative;
    padding-left: 1.5rem;
    padding-bottom: 4rem;
}

.secoundbox h3 {
    font-weight: 700;
}

.secoundbox p {
    max-width: 60%;
    width: 100%;
}

.secoundbox button {
    padding: .3rem 2.3rem;
    border: none;
    color: #EA4D06;
    text-transform: uppercase;
}

/* card css start   */
.outerdiv {
    background-color: #EA4D06;
    width: 100%;
    max-width: 25%;
    height: 15rem;
    top: 0;
    left: 0;
    position: relative;
}

.firstdiv {
    background-color: #EA4D06;
    /* padding: 10.2rem 11.2rem; */
    position: absolute;
    height: 329px;
    width: 27%;
    margin: 1rem 2rem;
}

.lastdiv{
        background-color: #EA4D06;
        width: 100%;
        max-width: 25.9%;
        height: 19rem;
        margin-top: 30%;
        right: 52px;
        position: absolute;
        z-index: -1;
}
.cards {
    padding-top: 4rem;
}

.cards .card {
    height: 300px;
    border: none;
    box-shadow: 0px 1px 10px -1px rgba(110, 109, 109, 0.75);
    -webkit-box-shadow: 0px 1px 10px -1px rgba(122, 120, 120, 0.75);
    -moz-box-shadow: 0px 1px 10px -1px rgba(131, 128, 128, 0.75);
}

.cards .card h6 {
    color: #EA4D06;
      font-weight: bolder;
}

.cards .card h2 {
    font-weight: bolder;

    font-size: 2.3rem;
}

.colle {
    margin: 3rem;
    font-weight: 900;
    color: #EA4D06;
    font-family: 'Comfortaa', cursive;
}

/* How can use it css start */
.uses {
    background-color: #f5f5f5;
    height: auto;
    width: 100%;
    padding-bottom: 2rem;
}

.uses h3 {
    padding: 2rem 0rem;
    font-weight: 600;
}

/* footer css Start */

.efooter {
    background-color: #22264C;
    height: auto;
    padding: 3rem 0rem;
}

.efsubmit {
    background-color: #EA4D06;
    color: #fff;
}
.footer {
    background-color: #0B1320;
    /* height: 400px; */
    margin-top: 4rem;
    padding: 4rem 0rem;
}
.forms{
    background-color: #fff;
    padding: 2rem 0rem;
    margin: 0rem 5rem;
    padding: 2rem 2rem;
   
   
}
.forms h4{
    font-weight: 600;
}
.forms input{
    background-color: rgb(228, 225, 225);
    width: 100%;
     border-radius: .2rem;
    height: 2.2rem;
    border: none;
    padding: 1.5rem .8rem;
}

.forms textarea{
    background-color: rgb(228, 225, 225);
     padding: 1rem .8rem;
    width: 100%;
    border-radius: .2rem;
    border: none;
}

.bottomfooter{
    text-align: center;
    
    }
.bottomfooter img{
 padding-left: .9rem;
}
 .footer button{
    background-color: #EA4D06;
    color: #fff;
 }

.bottomfooter h4{
    padding-top: 2rem;
}
.bottomfooter h4 img{
    margin-left: -10px;
}
@media screen and (max-width:575px) {
    .secoundbox {
        background-color: #EA4D06;
        color: #fff;
        height: auto;
        max-width: 100%;
        width: 100%;
        padding-top: 6.5rem;
        position: relative;
        padding-left: 1.5rem;
        padding-bottom: 4rem;
    }
    .firstbox img {
        display: none;
    }
    .firstdiv {
        background-color: #EA4D06;
        /* padding: 10.2rem 11.2rem; */
        position: absolute;
        height: 300px;
        width: 86%;
        margin: 1rem 1rem;
    }
    .lastdiv{
        display: none;
}
.secoundbox p {
     max-width: 86%; 
    width: 100%;
    text-align: justify;
}
.cards .card {
    height: 238px;
}
    
.forms {
    background-color: #fff;
    padding: 2rem 0rem;
    margin: 0rem 0rem;
    padding: 2rem 2rem;
}
}

@media (min-width: 575px) and (max-width:768px) {
.secoundbox {
    background-color: #EA4D06;
    color: #fff;
    height: auto;
    max-width: 60%;
    width: 100%;
    padding-top: 6.5rem;
    position: relative;
    padding-left: 1.5rem;
    padding-bottom: 4rem;
}
.secoundbox p {
    max-width: 72%;
    width: 100%;
}
.firstbox img {
    margin-top: 2.5rem;
    left: 8.5rem;
    
}
    .firstdiv {   
        padding: 9.2rem 9.2rem;
        margin: .1rem .1rem;
    }
    .forms {
        background-color: #fff;
        padding: 2rem 0rem;
        margin: 0rem 0rem;
        padding: 2rem 2rem;
    }
    .cards .card {
        height: 322px;
    }
}

@media (min-width: 768px) and (max-width:992px) {
    .firstbox img {
        margin-top: 2.5rem;
        left: 42.5%;
        position: relative;
        z-index: 1;
    }
    .cards .card {
        height: 322px;
    }
    .lastdiv{
        background-color: #EA4D06;
        width: 100%;
        max-width: 28.9%;
        height: 19rem;
        margin-top: 48%;
        right: 52px;
        position: absolute;
        z-index: -1;
}
}

